import actions from './actions'

const initialState = {
  driverInfo: null,
  driverInfoData: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,

  searchDriver: null,
  search: null,
  company_data: null,

  displayAddDrawer: false,
  creating: false,

  displayUpdateDrawer: false,
  updating: false,

  displayRemoveModal: false,
  removing: false,

  displayUploadModal: false,
  uploading: false,
}

export default function vendorReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_DATA:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      }

    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.driverInfo, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }

    case actions.REMOVE_DATA:
      return {
        ...state,
        displayRemoveModal: false,
        data: state.data.filter(o => action.payload._id !== o._id),
      }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDrawer: false }

    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.driverInfo, displayRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false }

    case actions.SHOW_UPLOAD:
      return { ...state, selectedData: action.payload.driverInfo, displayUploadModal: true }
    case actions.HIDE_UPLOAD:
      return { ...state, displayUploadModal: false }
    default:
      return state
  }
}
