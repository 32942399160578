import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Tabs } from 'antd'
import { Link } from 'react-router-dom'
import VehicleAction from 'redux/vehicle/actions'
import VehicleDetail from './VehicleDetail'
import DriverDetail from './DriverDetail'
import ServiceDetail from './ServiceDetail'
import FuelInfoDetail from './FuelInfo'
import VehicleDocuments from './VehicleDocuments'
import ExpensesInfo from './ExpensesInfo'

const { TabPane } = Tabs

const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
})

const StoreInfo = ({ dispatch, vehicle, intl: { formatMessage } }) => {
  const path = window.location.pathname
  const userId = path.replace('/manage/vehicle/', '')

  const vehicleDatas = vehicle?.IndividualVehicleInfodata

  const vehicleDocumentsDatas = vehicle?.IndividualVehicleDocumentsInfodata

  const DriverDatas = vehicle?.IndividualDriverInfodata

  const ServiceDatas = vehicle?.IndividualServiceInfodata

  const FuelDatas = vehicle?.IndividualFuelInfodata
  
  const ExpensesDatas = vehicle?.IndividualExpensesInfodata

  useEffect(() => {
    if (userId !== '/manage/vehicle') {
      dispatch({
        type: VehicleAction.LOAD_SINGLE_VEHICLE,
        payload: {
          vehicleId: userId,
        },
      })
    } else if (userId === '/manage/vehicle') {
      console.log('userId')
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (userId !== '/manage/vehicle') {
      dispatch({
        type: VehicleAction.LOAD_SINGLE_DRIVER,
        payload: {
          vehicleId: userId,
        },
      })
    } else if (userId === '/manage/vehicle') {
      console.log('userId')
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (userId !== '/manage/vehicle') {
      dispatch({
        type: VehicleAction.LOAD_SINGLE_SERVICE,
        payload: {
          vehicleId: userId,
        },
      })
    } else if (userId === '/manage/vehicle') {
      console.log('userId')
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (userId !== '/manage/vehicle') {
      dispatch({
        type: VehicleAction.LOAD_SINGLE_FUEL,
        payload: {
          vehicleId: userId,
        },
      })
    } else if (userId === '/manage/vehicle') {
      console.log('userId')
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (userId !== '/manage/vehicle') {
      dispatch({
        type: VehicleAction.LOAD_SINGLE_VEHICLE_DOCUMENT,
        payload: {
          vehicleId: userId,
        },
      })
    } else if (userId === '/manage/vehicle') {
      console.log('userId')
    }
  }, [dispatch, userId])


  useEffect(() => {
    if (userId !== '/manage/vehicle') {
      dispatch({
        type: VehicleAction.LOAD_SINGLE_VEHICLE_EXPENSES,
        payload: {
          vehicleId: userId,
        },
      })
    } else if (userId === '/manage/vehicle') {
      console.log('userId')
    }
  }, [dispatch, userId])

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'vehicle') {
    tabUrlVal = '1'
  } else if (tabname === 'driver') {
    tabUrlVal = '2'
  } else if (tabname === 'services') {
    tabUrlVal = '3'
  } else if (tabname === 'fuel') {
    tabUrlVal = '4'
  } else if (tabname === 'documents') {
    tabUrlVal = '5'
  }else if (tabname === 'expenses') {
    tabUrlVal = '6'
  }

  return (
    <div>
      <Helmet
        title={formatMessage({
          id: 'title.helmet.vehicle',
        })}
      />
      <div className="row">
        <div className="col-md-6">
          <h5 className="mb-4">
            <strong>{vehicleDatas?.vehicle_reg_no}</strong>
          </h5>
        </div>
      </div>
      <Tabs defaultActiveKey={tabUrlVal}>
        <TabPane
          tab={
            <Link to="?tab=vehicle">
              <span>{formatMessage({ id: 'text.tab.vehicleInfo' })}</span>
            </Link>
          }
          key={1}
        >
          <VehicleDetail apiRes={vehicleDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=documents">
              <span>{formatMessage({ id: 'text.tab.documents' })}</span>
            </Link>
          }
          key={5}
        >
          <VehicleDocuments apiRes={vehicleDocumentsDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=driver">
              <span>{formatMessage({ id: 'text.tab.driverInfo' })}</span>
            </Link>
          }
          key={2}
        >
          <DriverDetail apiRes={DriverDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=services">
              <span>{formatMessage({ id: 'text.tab.servicesInfo' })}</span>
            </Link>
          }
          key={3}
        >
          <ServiceDetail apiRes={ServiceDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=fuel">
              <span>{formatMessage({ id: 'text.tab.fuelInfo' })}</span>
            </Link>
          }
          key={4}
        >
          <FuelInfoDetail apiRes={FuelDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=expenses">
              <span>{formatMessage({ id: 'text.tab.expensesInfo' })}</span>
            </Link>
          }
          key={6}
        >
          <ExpensesInfo apiRes={ExpensesDatas} />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))
