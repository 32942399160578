import apiClient from 'services/axios'

const BASE_URL = 'api/user'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}/user-list/web`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/user-register-web`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(payload) {
  return apiClient
    .post(`${BASE_URL}/user-update`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(staffId) {
  return apiClient
    .put(`${BASE_URL}/user-delete/${staffId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
