import React, { Suspense, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Table, Spin, Tag } from 'antd'
import { injectIntl } from 'react-intl'
import { getFormattedDate } from 'utils/parser'
import VehicleAction from 'redux/vehicle/actions'
import UploadDocuments from './UploadDocuments'
import RemoveModal from './RemoveModal'

const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
})

const StoreSettings = ({ apiRes, vehicle, intl: { formatMessage } }) => {
  // const path = window.location.pathname
  // const userId = path.replace('/manage/vehicle/', '')
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const onViewStoreClick = values => {
    window.open(values?.fileurl)
  }

  const showCreate = () => {
    dispatch({
      type: VehicleAction.SHOW_DOC_DRAWER,
    })
  }

  const hideDocsUpload = () => {
    dispatch({
      type: VehicleAction.HIDE_DOC_DRAWER,
    })
  }

  const showDelete = values => {
    dispatch({
      type: VehicleAction.SHOW_DELETE_DOCUMENT,
      payload: {
        vehicleInfo: values,
      },
    })
  }

  const hideDelete = () => {
    dispatch({
      type: VehicleAction.HIDE_DELETE_DOCUMENT,
    })
  }

  const removeUser = () => {
    dispatch({
      type: VehicleAction.REMOVE_DOCUMENT,
      payload: {
        _id: vehicle?.selectedData?._id,
      },
    })
  }

  console.log('vehicle', vehicle)

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.type' }),
      render: (text, record) => (
        <>
          <Tag
            color={(() => {
              switch (text?.type) {
                case 'rcbook':
                  return 'green'
                case 'insurance':
                  return 'blue'
                case 'permit':
                  return 'orange'
                case 'tax':
                  return 'cyan'
                case 'others':
                  return 'grey'
                default:
                  return 'blue'
              }
            })()}
            style={{ textTransform: 'UPPERCASE' }}
          >
            {record?.type}
          </Tag>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.created_at' }),
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.ViewUrl' }),
      render: (text, record) => (
        <>
          <button type="button" className="btn btn-light" onClick={() => onViewStoreClick(record)}>
            <i className="fe fe-eye mr-1" aria-hidden="true" />
            {formatMessage({ id: 'action.viewStore' })}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-light" onClick={() => showDelete(text)}>
            <i className="fe fe-trash mr-1" aria-hidden />
          </button>
        </>
      ),
    },
  ]

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.vehicleDocs' }),
  }

  return (
    <div>
      <div className="mb-4">
        <div className="card">
          <div className="card-body">
            <div className="mb-4">
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn theme-btn-style"
                    onClick={showCreate}
                    style={{ float: 'right', marginTop: '2.5px' }}
                  >
                    <i className="fe fe-plus mr-1" aria-hidden="true" />
                    {formatMessage({ id: 'action.uploadDocument' })}
                  </button>
                </div>
              </div>
              <div className="kit__utils__table">
                <Suspense fallback={<Spin />}>
                  {apiRes?.length >= 0 ? (
                    <Table
                      dataSource={apiRes}
                      columns={columns}
                      locale={locale}
                      rowKey="_id"
                      pagination={{
                        onChange(current) {
                          setPage(current)
                        },
                      }}
                    />
                  ) : (
                    <Table
                      columns={columns}
                      locale={locale}
                      loading={{
                        indicator: (
                          <div>
                            <Spin />
                          </div>
                        ),
                      }}
                    />
                  )}
                </Suspense>
              </div>
            </div>
            <UploadDocuments
              visible={vehicle?.displayDocumentUploadDrawer}
              title={`Upload Docs - ${vehicle?.selectedData?.vehicle_reg_no}`}
              description={formatMessage({ id: 'text.uploadvehicleDocs.description' })}
              onCancel={hideDocsUpload}
              loading={vehicle?.linking}
            />
            <RemoveModal
              visible={vehicle?.displayRemoveDocumentModal}
              title={`Remove Document - ${vehicle?.selectedData?.type}`}
              description={formatMessage({ id: 'text.removeVehicleDocument.description' })}
              onSubmit={removeUser}
              onCancel={hideDelete}
              loading={vehicle?.removing}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
