import apiClient from 'services/axios'

const BASE_URL = 'api/vehicle'
const BASE_URL2 = 'api/report'

const BASE_URL1 = 'api/individual-vehicle'

export async function loadreport(payload) {
  return apiClient
    .post(`${BASE_URL2}/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/vehicle-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/add-vehicle`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(payload) {
  return apiClient
    .post(`${BASE_URL}/edit-vehicle`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatedriver(payload) {
  return apiClient
    .post(`${BASE_URL}/link-driver`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(staffId) {
  return apiClient
    .put(`${BASE_URL}/vehicle-delete/${staffId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function removedocument(staffId) {
  return apiClient
    .delete(`${BASE_URL}/vehicle-document-delete/${staffId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsinglevehicle(payload) {
  return apiClient
    .get(`${BASE_URL1}/individual-vehicle-detail/${payload?.vehicleId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateFuelInfo(payload) {
  return apiClient
    .post(`${BASE_URL1}/individual-vehicle-fuel-update`, payload?.payloadReq)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsingledriver(payload) {
  return apiClient
    .get(`${BASE_URL1}/individual-driver-detail/${payload?.vehicleId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsingleservice(payload) {
  return apiClient
    .get(`${BASE_URL1}/individual-service-detail/${payload?.vehicleId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsinglevehicledocs(payload) {
  return apiClient
    .get(`${BASE_URL1}/individual-vehicle-documents/${payload?.vehicleId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsinglefuel(payload) {
  return apiClient
    .get(`${BASE_URL1}/individual-fuel-detail/${payload?.vehicleId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadfilter(payload) {
  return apiClient
    .get(`${BASE_URL1}/individual-service-filter/${payload?.current_status}/${payload?.vehicleId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function loadvehicleexpenses(payload) {
  return apiClient
    .get(`${BASE_URL1}/individual-vehicle-expenses/${payload?.vehicleId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

