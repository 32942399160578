export default async function getMenuData() {
  return [
    // {
    //   title: 'Manage Accounts',
    //   key: 'manage-accounts',
    //   icon: 'fe fe-file',
    //   url: '/manage/accounts',
    // },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    {
      title: 'Manage Vehicle',
      key: 'manage-vehicle',
      icon: 'fe fe-users',
      url: '/manage/vehicle',
    },
    {
      title: 'Manage Vendor',
      key: 'manage-vendor',
      icon: 'fe fe-users',
      url: '/manage/vendor',
    },
    {
      title: 'Manage Fuel',
      key: 'manage-fuel',
      icon: 'fe fe-users',
      url: '/manage/fuel',
    },
    {
      title: 'Manage Driver',
      key: 'manage-driver',
      icon: 'fe fe-user',
      url: '/manage/driver',
    },
    {
      title: 'Manage Services',
      key: 'manage-services',
      icon: 'fe fe-settings',
      url: '/manage/services',
    },
    {
      title: 'Manage Reports',
      key: 'manage-reports',
      icon: 'fe fe-settings',
      url: '/manage/reports',
    },
  ]
}
