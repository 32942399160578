import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { create, loadAll, remove, update,loadsinglevendor,loadsinglevendorservice } from 'api/vendor'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { vendorList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        vendorInfoData: vendorList,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { vendorList } = response.data
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        vendorInfoData: vendorList,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  // const { UseridSel, updatedData } = payload
  const { response } = yield call(update, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { vendorList } = response.data
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        vendorInfoData: vendorList,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    const { vendorList } = response.data
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        vendorInfoData: vendorList,
      },
    })
  }
}


export function* LOAD_SINGLE_VENDOR({ payload }) {
  const { response } = yield call(loadsinglevendor, payload)
  if (response) {
    const { IndividualVendorDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualVendorInfodata: IndividualVendorDetail,
      },
    })
  }
}

export function* LOAD_SINGLE_SERVICE({ payload }) {
  const { response } = yield call(loadsinglevendorservice, payload)
  if (response) {
    const { IndividualServiceDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualVendorServiceInfodata: IndividualServiceDetail,
      },
    })
  }
}



export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.LOAD_SINGLE_VENDOR, LOAD_SINGLE_VENDOR),
    takeEvery(actions.LOAD_SINGLE_SERVICE, LOAD_SINGLE_SERVICE),
  ])
}
