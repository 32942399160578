export const staffRoles = [
  {
    key: 'superadmin',
    name: 'Support Admin',
    // hidden: true,
  },
  {
    key: 'admin',
    name: 'Admin',
  },
  {
    key: 'user',
    name: 'User',
  },
]

export const vehicleType = [
  {
    key: 'new',
    name: 'New',
  },
  {
    key: 'old',
    name: 'Old',
  },
]

export const vehicleFor = [
  {
    key: 'personal',
    name: 'Personal',
  },
  {
    key: 'official',
    name: 'Official',
  },
]

export const vehicleBodyType = [
  {
    key: 'two_wheeler',
    name: 'Two Wheeler',
  },
  {
    key: 'car',
    name: 'Car',
  },
  {
    key: 'van',
    name: 'Van',
  },
  {
    key: 'bus',
    name: 'bus',
  },
]

export const fuelvariant = [
  {
    key: 'petrol',
    name: 'Petrol',
  },
  {
    key: 'diesel',
    name: 'Diesel',
  },
  {
    key: 'cng',
    name: 'CNG',
  },
]

export const vendorcategory = [
  {
    key: 'new',
    name: 'New',
  },
  {
    key: 'old',
    name: 'Old',
  },
  {
    key: 'service',
    name: 'Service',
  },
]

export const serviceType = [
  {
    key: 'repair',
    name: 'Repair',
  },
  {
    key: 'fc',
    name: 'FC',
  },
]

export const documentType = [
  {
    key: 'rcbook',
    name: 'RC Book',
  },
  {
    key: 'insurance',
    name: 'Insurance',
  },
  {
    key: 'permit',
    name: 'Permit',
  },
  {
    key: 'tax',
    name: 'Tax',
  },
  {
    key: 'others',
    name: 'Others',
  },
]

export const ImageFormat = [
  {
    key: 'image',
    name: 'Image',
  },
  {
    key: 'pdf',
    name: 'PDF',
  },
]

export const daysArray = [
  {
    key: '1',
    name: '1',
  },
  {
    key: '2',
    name: '2',
  },
  {
    key: '3',
    name: '3',
  },
  {
    key: '4',
    name: '4',
  },
  {
    key: '5',
    name: '5',
  },
  {
    key: '6',
    name: '6',
  },
  {
    key: '7',
    name: '7',
  },
  {
    key: '8',
    name: '8',
  },
  {
    key: '9',
    name: '9',
  },
  {
    key: '10',
    name: '10',
  },
  {
    key: '11',
    name: '11',
  },
  {
    key: '12',
    name: '12',
  },
  {
    key: '13',
    name: '13',
  },
]

export const leadType = [
  {
    key: 'cold',
    name: 'Cold',
  },
  {
    key: 'warm',
    name: 'Warm',
  },
  {
    key: 'hot',
    name: 'Hot',
  },
]

export const BusinessType = [
  {
    key: 'Bar',
    name: 'Bar',
  },
  {
    key: 'Cafe',
    name: 'Cafe',
  },
  {
    key: 'Restaurant',
    name: 'Restaurant',
  },
]

export const paymentType = [
  {
    key: '1',
    name: 'Cheque',
  },
  {
    key: '2',
    name: 'Account Transfer',
  },
  {
    key: '3',
    name: 'GPay',
  },
  {
    key: '4',
    name: 'Cash',
  },
  {
    key: '5',
    name: 'Card',
  },
]
