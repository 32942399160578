const actions = {
  SET_STATE: 'vendor/SET_STATE',
  REMOVE_DATA: 'vendor/REMOVE_DATA',
  SHOW_CREATE: 'vendor/SHOW_CREATE',
  HIDE_CREATE: 'vendor/HIDE_CREATE',
  SHOW_UPDATE: 'vendor/SHOW_UPDATE',
  HIDE_UPDATE: 'vendor/HIDE_UPDATE',
  SHOW_DELETE: 'vendor/SHOW_DELETE',
  HIDE_DELETE: 'vendor/HIDE_DELETE',

  LOAD_ALL: 'vendor/LOAD_ALL',
  CREATE: 'vendor/CREATE',
  UPDATE: 'vendor/UPDATE',
  REMOVE: 'vendor/REMOVE',
  LOAD_SINGLE_VENDOR: 'vendor/LOAD_SINGLE_VENDOR',
  LOAD_SINGLE_SERVICE: 'vendor/LOAD_SINGLE_SERVICE',
}

export default actions
