import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Tabs } from 'antd'
import { Link } from 'react-router-dom'
import VendorAction from 'redux/vendor/actions'
import VendorDetail from './VendorDetail'
import ServiceInfo from './ServiceInfo'

const { TabPane } = Tabs

const mapStateToProps = ({ dispatch, vendor }) => ({
  dispatch,
  vendor,
})

const StoreInfo = ({ dispatch, vendor, intl: { formatMessage } }) => {
  const path = window.location.pathname
  const userId = path.replace('/manage/vendor/', '')

  const vendorDatas = vendor?.IndividualVendorInfodata

  const serviceDatas = vendor?.IndividualVendorServiceInfodata


  useEffect(() => {
    console.log("userId", userId)
    if (userId !== '/manage/vendor') {
      dispatch({
        type: VendorAction.LOAD_SINGLE_VENDOR,
        payload: {
          vendorId: userId,
        },
      })
    } else if (userId === '/manage/vendor') {
      console.log('userId')
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (userId !== '/manage/vendor') {
      dispatch({
        type: VendorAction.LOAD_SINGLE_SERVICE,
        payload: {
          vendorId: userId,
        },
      })
    } else if (userId === '/manage/vendor') {
      console.log('userId')
    }
  }, [dispatch, userId])

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'vendor') {
    tabUrlVal = '1'
  }
  else if (tabname === 'service') {
    tabUrlVal = '2'
  } 

  return (
    <div>
      <Helmet
        title={formatMessage({
          id: 'title.helmet.vendor',
        })}
      />
      <div className="row">
        <div className="col-md-6">
          <h5 className="mb-4">
            <strong>{vendorDatas?.name}</strong>
          </h5>
        </div>
      </div>
      <Tabs defaultActiveKey={tabUrlVal}>
        <TabPane
          tab={
            <Link to="?tab=vendor">
              <span>{formatMessage({ id: 'text.tab.vendorInfo' })}</span>
            </Link>
          }
          key={1}
        >
          <VendorDetail apiRes={vendorDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=service">
              <span>{formatMessage({ id: 'text.tab.servicesInfo' })}</span>
            </Link>
          }
          key={2}
        >
          <ServiceInfo apiRes={serviceDatas} />
        </TabPane>


      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))
