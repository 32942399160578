import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { create, loadAll, update, remove, sampleupload, getdocuments, removedocuments } from 'api/fuel'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { fuelExpenseList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        FuelExpenseInfodata: fuelExpenseList,
      },
    })
  }
}

export function* CREATE_FUEL({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_FUEL({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(update, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield call(LOAD_ALL)
  }
}


export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield call(LOAD_ALL)
  }
}

export function* SAMPLEUPLOAD({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(sampleupload, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPLOAD,
    })
    yield call(LOAD_ALL)
  }
}

export function* GET_UPLOADED_DOCUMENTS(payload) {
  const { response } = yield call(getdocuments, payload)
  if (response) {
    const { documentsList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        fuelDocumentsList: documentsList,
      },
    })
  }
}


export function* REMOVE_DOCUMENT({ payload }) {
  console.log("payload", payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { docId } = payload
  const { response } = yield call(removedocuments, docId)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPLOADED_DOCUMENTS,
    })
    yield call(LOAD_ALL)
  }
}




export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeEvery(actions.CREATE_FUEL, CREATE_FUEL),
    takeEvery(actions.UPDATE_FUEL, UPDATE_FUEL),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.SAMPLEUPLOAD, SAMPLEUPLOAD),
    takeEvery(actions.GET_UPLOADED_DOCUMENTS, GET_UPLOADED_DOCUMENTS),
    takeEvery(actions.REMOVE_DOCUMENT, REMOVE_DOCUMENT),
  ])
}
