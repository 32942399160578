import { createSelector } from 'reselect'

export const selectVehicle = state => state?.vehicle

export const selectedVehicleData = createSelector([selectVehicle], vehicle => {
  const { searchVehicle, VehicleInfodata } = vehicle
  if (searchVehicle && VehicleInfodata) {
    return VehicleInfodata.filter(
      r =>
        r?.vehicle_reg_no?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.owner_name?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.vehicle_brand?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.vehicle_model?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.fuel_variant?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.chasis_no?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.engine_no?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.rto_name?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.rto_city?.toLowerCase().includes(searchVehicle.toLowerCase()) ||
        r?.rto_state?.toLowerCase().includes(searchVehicle.toLowerCase()),
    )
  }
  return VehicleInfodata
})
