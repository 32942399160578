import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'
import vehicle from './vehicle/sagas'
import vendor from './vendor/sagas'
import driver from './driver/sagas'
import services from './services/sagas'
import fuel from './fuel/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    staff(),
    vehicle(),
    vendor(),
    driver(),
    services(),
    fuel(),
  ])
}
