import React, { Suspense, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Spin, Space, Tooltip, Button, Badge, Card } from 'antd'
import { injectIntl } from 'react-intl'
import { getFormattedDate } from 'utils/parser'

const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
})

const StoreSettings = ({ apiRes, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)



  let arrayCount=null
  if(apiRes?.length>0)
  {
    arrayCount=apiRes
  }

  

  const totalSpent = arrayCount?.reduce(
    // eslint-disable-next-line
    function
      (previousValue, currentValue) {
      return {
        amount: Number(previousValue?.amount) + Number(currentValue?.amount),
      }
    });

  const onExportClick = (values) => {
    window.open(values)
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.current_status' }),
      render: (record, text) => (
        <>
          <Badge.Ribbon text={record?.current_status}>
            <Card
              title={`${record?.service_reuest_id.toUpperCase()} - ${record?.service_type.toUpperCase()} | ${record?.category.toUpperCase()}`}
              size="small"
            >
              <center style={{ fontWeight: 'bold' }}>
                {`${text?.vehicle} - ${text?.owner_name} `}
              </center>
            </Card>
          </Badge.Ribbon>
        </>
      ),
    },

    {
      title: formatMessage({ id: 'text.description' }),
      dataIndex: 'description',
      key: 'description',
      render: text => (
        <>
          <Tooltip placement="top" title={text}>
            <Button type="primary">View Message</Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.service_start_date' }),
      dataIndex: 'service_start_date',
      key: 'service_start_date',
      render: (text, record) => (
        <>
          {record?.service_start_date !== null && (
            <>
              {getFormattedDate(text)}
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.service_end_date' }),
      dataIndex: 'service_end_date',
      key: 'service_end_date',
      render: (text, record) => (
        <>
          {record?.service_end_date !== null && (
            <>
              {getFormattedDate(text)}
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.amount' }),
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <>
          {record?.amount !== undefined && (
            <>
              {record?.amount} INR
            </>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.receipt_date' }),
      dataIndex: 'receipt_date',
      key: 'receipt_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.action' }),
      key: 'action',
      render: (record, text) => (
        <Space size="middle">
          <button type="button" className="btn btn-light mb-3 mb-md-0" onClick={() => onExportClick(text?.document_url)} hidden={text?.document_url === null}>
            <i className="fe fe-download mr-1" aria-hidden="true" />
          </button>
        </Space>
      ),
    },

  ]

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.NoServiceData' }),
  }

  const success = true

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <div className="col-md-4 col-sm-6 col-xs-12" hidden={totalSpent?.amount === undefined}>
              <div className="rounded border border-gray-3 mb-3 p-3" style={{ backgroundColor: '#03349A' }}>
                <div className="text-white-5 font-weight-bold font-size-14" style={{ color: '#ffffff' }}>Total Expenses</div>
                <div
                  className={`${success ? 'text-white' : 'text-white-6'} font-weight-normal font-size-24`}
                  style={{ color: '#ffffff' }}
                >
                  {totalSpent?.amount} INR
                </div>
              </div>
            </div>
            <div className="kit__utils__table">
              <Suspense fallback={<Spin />}>
                {apiRes?.length >= 0 ? (
                  <Table
                    dataSource={apiRes}
                    columns={columns}
                    locale={locale}
                    rowKey="_id"
                    pagination={{
                      onChange(current) {
                        setPage(current)
                      },
                    }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    locale={locale}
                    loading={{
                      indicator: (
                        <div>
                          <Spin />
                        </div>
                      ),
                    }}
                  />
                )}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
