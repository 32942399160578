import { createSelector } from 'reselect'

export const selectDriver = state => state?.driver

export const selectedDriverdata = createSelector([selectDriver], driver => {
  const { searchDriver, driverInfoData } = driver
  if (searchDriver && driverInfoData) {
    return driverInfoData.filter(
      r =>
        r?.driver_name?.toLowerCase().includes(searchDriver.toLowerCase()) ||
        r?.license_no?.toLowerCase().includes(searchDriver.toLowerCase()) ||
        r?.mobile_number?.toLowerCase().includes(searchDriver.toLowerCase()),
    )
  }
  return driverInfoData
})
